<div *ngIf="data && chartOptions" class="position-relative d-flex flex-column">
	<mat-select *ngIf="show_charts"
							class="btn select-outline-dark-primary chart-select"
							[ngModel]="chart_type"
							(ngModelChange)="chart_typeChange.emit($event)">
		<mat-option *ngFor="let item of chart_types" [value]="item">{{ item }}</mat-option>
  </mat-select>
    <apx-chart
      [title]="chartOptions.title"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [legend]="chartOptions.legend"
      [colors]="chartOptions.colors"
      [grid]="chartOptions.grid"
      [labels]="chartOptions.labels"
      [responsive]="chartOptions.responsive"
    ></apx-chart>
  </div>