import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexGrid,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  labels: any;
  responsive: ApexResponsive[];
};

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class FootprintChartComponent implements OnChanges {
  @ViewChild('chart') chart: ChartComponent;

  @Input() title = '';

  @Input() show_charts = true;
  @Input() vertical_dataLabels = false;

  @Input() chart_type: 'bar' | 'area' | 'pie' | 'donut' = 'bar';
  @Output() chart_typeChange = new EventEmitter<string>();

  @Input() height = 450;
  @Input() show_toolbar = true;

  @Input() colors: string[] = [
    '#008080',
    '#61AEAE',
    '#DBE7E7',
    '#90CFCF',
    '#45b1a2',
    '#31CFD9',
    '#006969',
    '#26a69a',
    '#0FB8B8'
  ];

  @Input() data: any = null;

  @Input() distributed_colors = true;

  protected chartOptions: Partial<ChartOptions>;

  ngOnChanges(changes: SimpleChanges) {
    const anyChanged = Object.values(changes).some(c => c.currentValue !== c.previousValue);
    if (anyChanged) {
      this.initChart();
    }
  }


  initChart() {
    let {labels, series} = this.data || {};
    if(!series) return;
    this.chartOptions = {
      labels: labels||[],
      title: {
        text: this.title,
        align: 'center',
        offsetY: -30,
        style: {
          fontSize: '18px',
          fontWeight: 600,
          color: '#708090'
        }
      },
      series,
      chart: {
        height: this.height,
        type: <any>this.chart_type,
        stacked: this.chart_type === 'bar',
        zoom: {
          enabled: false
        },
        toolbar:{
          show: this.show_toolbar
        },
      },
      colors: this.colors,
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: this.distributed_colors,
          //borderRadius: 5, // this.borderRadius,
          //borderRadiusApplication: 'end',
          hideZeroBarsWhenGrouped: false,
          columnWidth: series.length<=1 ? '40%'  : '60%',
          barHeight: "90%",
          dataLabels: {
            orientation: this.vertical_dataLabels ? 'vertical' : 'horizontal',
            position: "center"
          }
        }
      },
      legend: {
        show: ['donut', 'pie'].includes(this.chart_type)
      },
      grid: {
        show: false
      },
      //borderRadiusWhenStacked: 'all',
      responsive : [
        {
          breakpoint: this.height,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      xaxis: {
        type:'category',
        labels:{
          show: true,
          offsetY: -12,
          style:{
            fontSize: '10px',
            cssClass:'xAxisLabel',
          }
        },
        categories: (labels||[]).map(item=>{
          let isGreen = ((this.data.data||{})[item]||[]).some(item=>item.isGreen==true);
          //let renewableTags = ["Solar Panels","Wind Turbine","Hydro Electricity"];
          //let renewable =  renewableTags.some(key=> item.indexOf(key)>=0);
          let isPublished = (this.data.published||[]).find(data=>data.reportingPeriod==item||data.reportingYear==item);
          let renewableImg = isGreen ? ['\uF8F6']:[''];
          let publishedImg = isPublished ? ['','\uF26E']:[''];
          //let publishedImg = isPublished ? ['','<i class="fa fa-check text-success"></i>'/*, '\uF26E'*/]:[''];
          let newLabels:string[] =  labels?.length >2 ? item?.split(' ') : [];
          //newLabels = newLabels.filter((val, index, self)=>self.indexOf(val)==index); // return unique
          if(labels?.length <=2){
            while (item && item.length>0) {
              let splitString3 = ((item||'').match(/(.*?\s){3}/g) ||[''])[0].trim();
              let splitString2 = ((item||'').match(/(.*?\s){2}/g)||[''])[0].trim();
              let splitString = ((item||'').match(/(.*?\s)/g)||[''])[0].trim();
              if(splitString3 && splitString3.length<=50){
                item = (item||'').replace(splitString3,'');
                //if(!newLabels.includes(splitString3))
                newLabels.push(splitString3);
              } else if(splitString2 && splitString2.length<=50){
                item = (item||'').replace(splitString2,'');
                //if(!newLabels.includes(splitString2))
                newLabels.push(splitString2);
              } else if(splitString && splitString.length>=20){
                item = (item||'').replace(splitString,'');
                //if(!newLabels.includes(splitString))
                newLabels.push(splitString);
              } else {
                //if(!newLabels.includes(item.trim()))
                newLabels.push(item.trim());
                item = '';
              }
            }
          }

          if(item && newLabels && newLabels.length==0)
            newLabels = [item];
          return [...renewableImg, ...newLabels, ...publishedImg];
        })
      },
      yaxis: {
        labels:{
          show:false,
          offsetX:-15
        }
      }
    };
    if (['donut', 'pie'].includes(this.chart_type)) {
      if(this.chartOptions.labels && this.chartOptions.labels.length>=0) {
        let series = Array(this.chartOptions.labels.length).fill(0);
        (this.chartOptions.series||[]).forEach(element => {
          (element.data||[]).forEach((child, index) => {
            series[index]+=child;
          });

        });
        this.chartOptions.series = series;
      }
    }
  }

  protected readonly chart_types: string[] = ['bar', 'area', 'pie', 'donut'];
}

